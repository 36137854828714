import Breakpoints from "./__breakpoints";
import SplitText from '../vendor/gsap/SplitText.min'
import fullpage from 'fullpage.js';


class FullpageSlider {
    constructor(container, breakpoints) {

        if (breakpoints.isInside()) {
            console.log("inside range");
            // this.initMobile(container);
        } else {
            console.log("outside range");
            this.initDesktop(container);
        }

    }


    // initMobile(container) {
    //     console.log("init mobile");

    //     let that = this;


    //     this.slides = [];
    //     container.find('.fullpage-slider__slide').each((k, v) => {

    //         let
    //             slide = $(v),
    //             img = slide.find('.fullpage-slider__img'),
    //             curtain = slide.find('.fullpage-slider__curtain'),
    //             hl = slide.find('.fullpage-slider__hl'),
    //             hl_splitText = new SplitText(hl, {
    //                 type: "lines, words"
    //             }),
    //             hl_lines = hl_splitText.lines,
    //             hl_words = hl_splitText.words,
    //             hl_chars = hl_splitText.chars,
    //             tl = gsap.timeline({
    //                 delay: 0.3,
    //                 onComplete: () => {
    //                     hl_splitText.revert();
    //                 }
    //             });

    //         tl
    //             .from(img, {
    //                 y: "-=100",
    //                 opacity: 0,
    //                 delay: duration * 0.62,
    //                 duration: duration * 0.62
    //             }, "a")
    //             .from(curtain, {
    //                 y: "0",
    //                 height: "100%",
    //                 delay: duration * 0.62,
    //                 duration: duration * 0.62,
    //                 ease: "cubic-bezier(0.645, 0.045, 0.355, 1)"
    //             }, "a")
    //             .from(hl_lines, {
    //                 delay: duration * 0.62,
    //                 duration: duration * 0.62,
    //                 opacity: 0,
    //                 y: "-=50",
    //                 ease: "cubic-bezier(0.645, 0.045, 0.355, 1)",
    //                 stagger: 0.1
    //             }, "a")
    //             .pause()

    //         slide.timeline = tl;
    //         that.slides.push(slide);

    //     });

    //     // Fullpage.js
    //     // ------------

    //     let duration = 1;


    //     that.fullpageInstance = new fullpage('#fullpage', {

    //         licenseKey: 'C96ACBF2-F8504A8E-8D66B1AF-CB9A43A9',
    //         autoScrolling: true,
    //         scrollingSpeed: duration * 1000,
    //         easingcss3: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    //         responsiveWidth: 1024,

    //         afterLoad: function (slide) {
    //             gsap.to(container, {
    //                 opacity: 1,
    //                 duration: 0.3
    //             });
    //             that.slides[slide.index].timeline.play();
    //         },

    //         onLeave: function (origin, destination, direction) {
    //             that.slides[destination.index].timeline.play(0);
    //             that.slides[origin.index].timeline.reverse(0);
    //         }
    //     });
    // }


    initDesktop(container) {

        console.log("init desktop");

        let that = this;
        let duration = 1;

        // Slide objects
        // ------------

        this.slides = [];
        container.find('.fullpage-slider__slide').each((k, v) => {

            let
                slide = $(v),
                img = slide.find('.fullpage-slider__img'),
                curtain = slide.find('.fullpage-slider__curtain'),
                hl = slide.find('.fullpage-slider__hl'),
                hl_splitText = new SplitText(hl, {
                    type: "lines, words"
                }),
                hl_lines = hl_splitText.lines,
                hl_words = hl_splitText.words,
                hl_chars = hl_splitText.chars,
                tl = gsap.timeline({
                    delay: 0.3,
                    onComplete: () => {
                        hl_splitText.revert();
                    }
                });

            tl
                .from(img, {
                    y: "-=100",
                    opacity: 0,
                    delay: duration * 0.62,
                    duration: duration * 0.62
                }, "a")
                .from(curtain, {
                    y: "0",
                    height: "100%",
                    delay: duration * 0.62,
                    duration: duration * 0.62,
                    ease: "cubic-bezier(0.645, 0.045, 0.355, 1)"
                }, "a")
                .from(hl_lines, {
                    delay: duration * 0.62,
                    duration: duration * 0.62,
                    opacity: 0,
                    y: "-=50",
                    ease: "cubic-bezier(0.645, 0.045, 0.355, 1)",
                    stagger: 0.1
                }, "a")
                .pause()

            slide.timeline = tl;
            that.slides.push(slide);

        });


        // Fullpage.js
        // ------------

        that.fullpageInstance = new fullpage('#fullpage', {

            licenseKey: 'C96ACBF2-F8504A8E-8D66B1AF-CB9A43A9',
            autoScrolling: true,
            scrollingSpeed: duration * 1000,
            easingcss3: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
            responsiveWidth: 1024,

            afterLoad: function (slide) {
                gsap.to(container, {
                    opacity: 1,
                    duration: 0.3
                });
                that.slides[slide.index].timeline.play();
            },

            onLeave: function (origin, destination, direction) {
                that.slides[destination.index].timeline.play(0);
                that.slides[origin.index].timeline.reverse(0);
            }
        });
    }



    destroy(container) {
        console.log("destroy fullpage");
    }


}

export default FullpageSlider;
