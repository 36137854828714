import {
    EventEmitter
} from "events";

class Breakpoints {

    /**
     * Trigger event if inside the breakpoints-range
     * For example: ["xs", "sm", "md"]
     * @param ["Breakpoint"] range 
     */
    constructor(breakpoint, direction) {

        console.log(breakpoint, direction);

        let that = this;

        this.detector = $('#breakpoint-detector');
        this.breakpoint = breakpoint;
        this.direction = direction;
        this.ranges = this.calculateRanges(breakpoint, direction);
        
        console.log(this.ranges);

        // breakpointChange() = () => {
        //     this.dispatcher.trigger("breakpointChange");
        // }

        // Check if input is ok
        // if(range === null || range === undefined || range.length === 0) console.error("You forgot to set a range on breakpoints (array)");

        // Store the current breakpoint

        // Create EventEmitter object to report a breakpoint change to another module
        this.breakpointAlert = new EventEmitter();

        // Fire Event when breakpoint has changed
        this.breakpointChange();

        // Fire Event when breakpoint range has changed
        this.breakpointRangeChange();

        // Fire Event when breakpoint range has changed
        this.breakpointRangeChange = () => {
            
            function checkBreakpointRange() {
                
                // Get current breakpoint
                let currentBreakpoint = that.getBreakpoint();
                
                console.log(that.breakpoint, currentBreakpoint);

                let found = that.ranges[0].find(bp => bp === currentBreakpoint);
                console.log(found);
                if(found !== undefined) {
                    // console.log(found);
                    // alert("boom");
                }

                // If the breakpoint changed
                if (that.breakpoint !== currentBreakpoint) {

                    // Set new breakpoint
                    // that.breakpoint = currentBreakpoint;

                    // Emit notification with new breakpoint
                    that.breakpointAlert.emit("breakpointChange", currentBreakpoint);

                    

                }

            }

            // Check breakpoint on window resize
            $(window).on('resize', checkBreakpointRange);

        }

        this.breakpointRangeChange();

        console.log(this);

    }


    calculateRanges(breakpoint, direction) {

        let
            bpOrigIndex = this.detector.find('#' + breakpoint).index(),
            ranges = [],
            inside = [],
            outside = [];

        this.detector.children().each((k, v) => {
            if (eval(parseInt(bpOrigIndex) + direction + parseInt($(v).index()))) {
                outside.push($(v).attr('id'));
            } else {
                inside.push($(v).attr('id'));
            }
        });

        ranges.push(inside, outside);

        return ranges;
    }


    /**
     * Fire event on every breakpoint change
     */
    breakpointChange() {

        let that = this;

        // Set initial breakpoint
        that.breakpoint = that.getBreakpoint();

        function checkBreakpoint() {

            // Get current breakpoint
            let screen = that.getBreakpoint();

            // See if the breakpoint changed
            if (that.breakpoint !== screen) {

                // Set new breakpoint
                that.breakpoint = screen;

                // Emit notification with new breakpoint
                that.breakpointAlert.emit("breakpointChange", screen);

            }

        }

        // Check breakpoint on window resize
        $(window).on('resize', checkBreakpoint);

    }



    /**
     * Fire event on breakpoint range change
     */
    breakpointRangeChange() {

        let that = this;
        let initial = false;
        let current = false;

        // Get initial Breakpoint
        $.each(that.range, function (key, element) {
            if (that.getBreakpoint() === element) initial = true;
        });

        // Receive breakpoint changes from Breakpoints module => Import breakpoints module
        function checkBreakpointRange() {

            // Check if inside the breakpoints range
            $.each(that.range, function (key, element) {
                if (that.getBreakpoint() === element) {
                    current = true;
                    return false;
                } else {
                    current = false;
                }
            });

            // Check if breakpoints range has changed
            if (initial !== current) {
                initial = current;
                that.breakpointAlert.emit("breakpointRangeChange", current);
            }

        }

        // Check breakpoint on window resize
        $(window).on('resize', checkBreakpointRange);

    }



    /**
     * Get the current visible breakpoint
     */
    getBreakpoint() {
        return this.detector.find('div:visible').first().attr('id');
    }



    /**
     * Get the current visible breakpoint range
     */
    getRange() {
        let breakpoint = this.getBreakpoint();
        console.log(breakpoint, this.breakpoint, this.range);
        let test = this.range.find(element => element === breakpoint);
        console.log(test);
        // return this.container.find('div:visible').first().attr('id');
    }



    /**
     * true if breakpoint is inside the breakpoint range
     */
    isInside() {
        let that = this;
        let inside = false;
        $.each(that.range, function (key, element) {
            if (that.getBreakpoint() === element) inside = true;
        });
        return inside;
    }



    /**
     * true if breakpoint is inside the breakpoint range
     */
    isInsideRange() {
        let that = this;
        let inside = false;
        $.each(that.range, function (key, element) {
            if (that.getBreakpoint() === element) inside = true;
        });
        return inside;
    }


    // USE THIS IN OTHER MODULES TO CONTROL THE CODE ON BREAKPOINT-CHANGE

    // /**
    //  * Trigger event if inside the breakpoints-range
    //  * For example: ["xs", "sm", "md"]
    //  * @param {Strings} breakpointsArray 
    //  */

    // onBreakpointChange(breakpointsArray) {

    // 	let that = this;
    // 	let mobile = false;

    // 	// Receive breakpoint changes from Breakpoints module => Import breakpoints module
    // 	this.breakpoints.alert.on("breakpointChange", function(){

    // 		console.log("New breakpoint: ", that.breakpoints.breakpoint);

    // 		// Inside the breakpoints range
    // 		breakpointsArray.forEach(element => {
    // 			if(that.breakpoints.breakpoint === element) {
    // 				mobile = true;
    // 				console.log("Inside breakpoints range");
    // 				// change something ...
    // 			}
    // 		})

    //		// Outside the breakpoints range
    // 		if(!mobile) {
    // 			console.log("Outside breakpoints range");
    // 			// change something ...
    // 		}

    // 	});

    // }

}

export default Breakpoints;
