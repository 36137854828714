class Scrollspy {

    constructor(container) {

        // this.init(container);

        // console.warn("checking out scrollspy");

        this.scrollspy = null;
        let scrollspy_target = $(container.data('scrollspy'));
        // console.log(scrollspy_target);

        // console.log(this.scrollspy);
        let scrollspy_offset = Math.ceil(container.offset().top);

        this.scrollspy = $('body').scrollspy({
            target: scrollspy_target,
            offset: scrollspy_offset
        });

        // console.log(this.scrollspy, this);

    }


}

export default Scrollspy;
