class PinnedLabels {

    constructor(labels) {

        this.controller = new ScrollMagic.Controller();

        labels.each((k, v) => {

            let
                label = $(v),
                labelClass = "." + label.attr('class'),
                container = $(label.parent('.group__container')),
                containerID = "#" + container.attr('id'),
                labelIdentifier = containerID + " " + labelClass,
                offset = -$('#header').outerHeight(),
                // offset = 0,
                duration = container.innerHeight() - label.outerWidth(true);

            // create a scene
            let scene = new ScrollMagic.Scene({
                    triggerElement: containerID,
                    duration: duration,
                    offset: offset,
                    // reverse: true
                })
                .triggerHook(0)
                .setPin(labelIdentifier, {
                    pushFollowers: false
                })
                .addIndicators()
                .addTo(this.controller);

            scene
                .on("enter", function (event) {
                    offset = $('#header').outerHeight(true);
                    scene.offset(-offset);
                })

            // console.log(labelClass, containerID, labelIdentifier, duration, label.outerHeight());

        });

    }

}

export default PinnedLabels;
