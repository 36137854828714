import 'gsap';
import drawSVG from '../vendor/gsap/esm/DrawSVGPlugin';
import '../vendor/gsap/esm/EasePack';


// Fix jquery tree shaking issue with this
window.jQuery = window.$ = require("jquery");
gsap.registerPlugin(drawSVG);

class MobileNav {

    /**
     * 
     * @param {Object} container jQuery object that contains the navigation menu and burger button
     * @param {Object} breakpoints range of bootstrap breakpoints (array) in which the mobile navigation will be active
     */
    constructor(container, breakpoints) {

        console.log("init mobile nav");
        
        
        let that = this;
        this.breakpoints = breakpoints;
        
        // Create mobile navigation timelines only if inside the mobile breakpoints
        // if (breakpoints.constructor.name === "Breakpoints") {
            
            console.log(breakpoints.isInside());
            console.log(breakpoints.getBreakpoint());
            
            // Create mobile Nav
            if (breakpoints.isInside()) {
                console.log(window.innerWidth);
            // if (window.innerWidth < 768) {
                that.init(container);
            }

            // Create mobile navigation if breakpoints change to mobile
            breakpoints.alert.on("breakpointChange", function () {
                if (breakpoints.isInside()) {
                    console.log("Breakpoint changed to mobile, initialize mobile Navigation: ", breakpoints.getBreakpoint());
                    that.init(container);
                } else {
                    if (that.timeline != null) {
                        console.log("Breakpoint changed -> destroy mobile Navigation");
                        that.destroy(container);
                    }
                }
            });
        // }


        // mobileNav object
        console.log(this);

    }



    // Initialize mobile navigation
    init(container) {

        let that = this;

        // Variables 
        let burgerButton = container.find('.nav__burger'),
            menu = container.find('.nav__menu');

        // Main timeline 
        this.timeline = gsap.timeline({
                id: "main"
            })
            .add(this.createBurgerTimeline(container), "a")
            .add(this.createMenuTimeline(container), "a")
            .pause();

        // Handle click
        burgerButton.click(() => {
            if (!this.timeline.isActive()) {
                if (this.timeline.progress()) {
                    this.deactivate();
                } else {
                    this.activate();
                }
            }
        });

        // Activate mobile Navigation
        this.activate = () => {
            console.log("activate mobile Nav", this);
            this.timeline.play();
        };

        // Deactivate mobile Navigation
        this.deactivate = () => {
            console.log("deactivate mobile Nav", this);
            this.timeline.reverse();
        };

        // Show burger button smoothly on page load
        gsap.to(burgerButton, {
            duration: 0.4,
            autoAlpha: 1
        });

    }


    // Create burger sub timeline
    createBurgerTimeline(container) {

        let
            burgerTimeline = gsap.timeline({
                id: "burger",
                defaults: {
                    duration: 0.3,
                    stagger: 0.1,
                    ease: "power2.out"
                }
            }),
            burger = container.find('#burger line'),
            close = container.find('#close line');

        burgerTimeline

            .fromTo(burger, {
                drawSVG: "0% 100%"
            }, {
                drawSVG: "0% 0%"
            }, "burger")

            .fromTo(close, {
                drawSVG: "100% 100%"
            }, {
                drawSVG: "0% 100%",
            }, "close-=0.15");

        return burgerTimeline;

    }


    // Create menu sub timeline
    createMenuTimeline(container) {

        let
            menuTimeline = gsap.timeline({
                id: "menu",
                defaults: {
                    duration: 0.3,
                    stagger: 0.1,
                    ease: "power2.out"
                }
            }),
            menu = container.find('.nav__menu'),
            curtain = container.find('.nav__curtain'),
            links = container.find('.nav__link');

        menuTimeline

            .set([menu, curtain], {
                css: {
                    display: "flex"
                }
            })

            .from(curtain, {
                autoAlpha: 0,
            }, "curtain")

            .from(links, {
                x: "-=5",
                autoAlpha: 0,
            }, "links");

        return menuTimeline;

    }


    // Destroy mobile navigation timelines, tweens and DOM transformations
    destroy() {

        clearTimelineProps(this.timeline);
        this.timeline.kill();
        this.timeline = null;

        function clearTimelineProps(timeline) {

            let tweens = timeline.getChildren();
            $.each(tweens, function (i, tween) {
                gsap.set(tween._targets, {
                    clearProps: "all"
                });
            });
        }

    }

}

export default MobileNav;
