// import 'gsap';
// import '../vendor/gsap/ScrollToPlugin';

// Fix jquery.waypoints issue with this
window.jQuery = window.$ = require("jquery");

class Scrollto {

    constructor(link) {

		link.click(function(e){
			e.stopPropagation();			
			e.preventDefault();
						
			let target = $(e.currentTarget).attr("href"); 
			console.log(target);
			$('html, body').animate({
				scrollTop: $(target).offset().top - $('#header').outerHeight(true)
			}, 'slow');
		});

    }


    // init(link) {

	// 	let that = this;
		
	// 	link.click(function(e){
	// 		e.stopPropagation();			
	// 		e.preventDefault();
						
	// 		let target = $(e.currentTarget).attr("href"); 
	// 		console.log(target);
	// 		$('html, body').animate({
	// 			scrollTop: $(target).offset().top - $('#header').outerHeight(true)
	// 		}, 'slow');
	// 	});

	// }

}

export default Scrollto;
