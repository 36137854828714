import {
    EventEmitter
} from "events";

class Breakpoints {

    /**
     * Trigger event if inside the breakpoints-range
     * For example: ["xs", "sm", "md"]
     * @param ["Breakpoint"] range 
     */
    constructor(range) {

		console.log("create breakpoints");

        this.container = $('#breakpoint-detector');

        // Breakpoints range (Array)
        this.range = range;

        // Check if input is ok
        if (range === null || range === undefined || range.length === 0) console.error("You forgot to set a range on breakpoints (array)");

        // Store the current breakpoint
        this.breakpoint = "not defined yet";

        // Create EventEmitter object to report a breakpoint change to another module
        this.alert = new EventEmitter();

        // Fire Event when breakpoint has changed
        this.breakpointChange();

        // Fire Event when breakpoint range has changed
        this.breakpointRangeChange();

    }


    /**
     * Fire event on every breakpoint change
     */
    breakpointChange() {

        let that = this;

        // Set initial breakpoint
        that.breakpoint = that.getBreakpoint();

        function checkBreakpoint() {

            // Get current breakpoint
            let screen = that.getBreakpoint();

            // See if the breakpoint changed
            if (that.breakpoint !== screen) {

                // Set new breakpoint
                that.breakpoint = screen;

                // Emit notification with new breakpoint
                that.alert.emit("breakpointChange", screen);

            }

        }

        // Check breakpoint on window resize
        $(window).on('resize', checkBreakpoint);

    }



    /**
     * Fire event on breakpoint range change
     */
    breakpointRangeChange() {

        let that = this;
        let initial = false;
        let current = false;

		console.log(that.range);

        // Get initial Breakpoint
        $.each(that.range, function (key, element) {
            if (that.getBreakpoint() === element) initial = true;
        });

        // Receive breakpoint changes from Breakpoints module => Import breakpoints module
        function checkBreakpointRange() {

            // Check if inside the breakpoints range
            $.each(that.range, function(key, element) {
            	if(that.getBreakpoint() === element) {
            		current = true;
            		return false;
            	} else {
            		current = false;
            	}
            });

            // Check if breakpoints range has changed
            if (initial !== current) {
                initial = current;
                that.alert.emit("breakpointRangeChange", current);
            }

        }

        // Check breakpoint on window resize
        $(window).on('resize', checkBreakpointRange);

    }



    /**
     * Get the current visible breakpoint
     */
    getBreakpoint() {
        return this.container.find('div:visible').first().attr('id');
    }



    /**
     * Get the current visible breakpoint range
     */
    getRange() {
        let breakpoint = this.getBreakpoint();
        let test = this.range.find(element => element === breakpoint);
        // return this.container.find('div:visible').first().attr('id');
    }



    /**
     * true if breakpoint is inside the breakpoint range
     */
    isInside() {
        let that = this;
        let inside = false;
        $.each(that.range, function (key, element) {
            if (that.getBreakpoint() === element) inside = true;
        });
        return inside;
    }


    // USE THIS IN OTHER MODULES TO CONTROL THE CODE ON BREAKPOINT-CHANGE

    // /**
    //  * Trigger event if inside the breakpoints-range
    //  * For example: ["xs", "sm", "md"]
    //  * @param {Strings} breakpointsArray 
    //  */

    // onBreakpointChange(breakpointsArray) {

    // 	let that = this;
    // 	let mobile = false;

    // 	// Receive breakpoint changes from Breakpoints module => Import breakpoints module
    // 	this.breakpoints.alert.on("breakpointChange", function(){

    // 		console.log("New breakpoint: ", that.breakpoints.breakpoint);

    // 		// Inside the breakpoints range
    // 		breakpointsArray.forEach(element => {
    // 			if(that.breakpoints.breakpoint === element) {
    // 				mobile = true;
    // 				console.log("Inside breakpoints range");
    // 				// change something ...
    // 			}
    // 		})

    //		// Outside the breakpoints range
    // 		if(!mobile) {
    // 			console.log("Outside breakpoints range");
    // 			// change something ...
    // 		}

    // 	});

    // }

}

export default Breakpoints;
