// Vendor

// Custom Modules
import Breakpoints from "./modules/breakpoints";

// import '@fancyapps/fancybox';
// import Images from './modules/images';
import MobileNav from './modules/mobileNav';
import simpleParallax from 'simple-parallax-js';
// import Maps from './modules/maps';
import Slider from './modules/slider';
import Scrollspy from './modules/scrollspy';
// import Video from './modules/video';
import Scrollto from './modules/scrollto';
// import MinimizeHeader from './modules/minimizeHeader';
import PinnedLabels from './modules/pinnedLabels';
import FullpageSlider from './modules/fullpageSlider';


const breakpoints = new Breakpoints(["xs", "sm", "md"]);
// const breakpoints = new Breakpoints('lg', '<=');

// Minimize Header on scroll
if ($('.group__label').length > 0) {
    new PinnedLabels($('.group__label'));
}

// Initialize mobile Navigation
$(window).ready(function () {
    new MobileNav($('nav.nav'), breakpoints);
});

let image = document.getElementsByClassName('parallax--vertical');
new simpleParallax(image, {
    scale: 1.2,
    orientation: 'up',
});

if ($('.scrollto').length > 0) {
    new Scrollto($('.scrollto'));
}

// Initialize Swiper Slider
if ($('.swiper-container--images').length > 0) {
    new Slider($('.swiper-container--images'));
}


if ($('.scrollspy').length > 0) {
    new Scrollspy($('.scrollspy'));
}



let fullpage = $('#fullpage');
if (fullpage.length > 0) {
    new FullpageSlider(fullpage, breakpoints);
}
